import { apiService } from 'core/services/http.service'
import { debugErrorLog, debugLog } from 'core/utils'
import AuthStorageManager from 'data/storage/auth.storage.ts'
import AuthClientsStorageManager from 'data/storage/auth_clients.storage.ts'
import ProductCategoriesStorageManager from 'data/storage/product_categories.storage.ts'

class _AuthProvider {
  constructor() {
    this._user = AuthStorageManager.get()
  }

  // Get the user
  get user() {
    return this._user
  }

  // Used to check if the user is authenticated
  isAuthenticated = () => {
    return this._user !== null && this._user.email !== undefined
  }

  // Used to check if the user is a admin
  isAdmin = () => {
    return this.isAuthenticated() && this._user.isAdmin
  }

  // Used to check if the user is a admin
  isReadOnlyAdmin = () => {
    return this.isAuthenticated() && this._user.isReadOnlyAdmin
  }

  // Used to check if the user is super user
  isSuperUser = () => {
    return this.isAuthenticated() && this._user.isSuperUser
  }

  // Used to check if the user is a simple user
  isSimpleUser = () => {
    return (
      this.isAuthenticated() &&
      this.isSuperUser() === false &&
      this.isAdmin() === false &&
      this.isReadOnlyAdmin() === false
    )
  }

  // Used to check if the user is authenticated
  authUpdated = (auth) => {
    AuthStorageManager.clean()
    this._user = AuthStorageManager.save(auth)
    return this._user
  }

  // Used to check if the user is authenticated
  checkIfAuthenticated = async () => {
    return apiService
      .get('/auth')
      .then((response) => {
        AuthStorageManager.clean()
        this._user = AuthStorageManager.save(response.data.auth)
        return this._user
      })
      .catch((error) => {
        debugErrorLog('Error on checkIfAuthenticated request: ', error)
        return false
      })
  }

  // Make login request
  login = async (data) => {
    debugLog('authLogin: ', data)

    await apiService
      .post('/user/login', data)
      .then((response) => {
        debugLog('User logged in')
        this._user = AuthStorageManager.save(response.data.auth)
        return this._user
      })
      .catch(async (error) => {
        // for the case that the user is login and the data was clean from the local storage for some reason
        if (error?.response?.status === 403) {
          // try to get the user from the server again if the user is already logged in on server side
          await this.checkIfAuthenticated()
            .then((response) => {
              if (response) {
                return this._user
              }
            })
            .catch((error) => {
              debugLog('Error on login verification request: ', error)
              throw error
            })
        } else {
          debugLog('Error on login request: ', error)
          throw error
        }
      })
  }

  /*
   * Request to recover password
   * When the user forgot the password
   */
  recoverPassword = async (data) => {
    debugLog('Making recover password: ', data)

    await apiService
      .post('/user/recover-password', data)
      .then((response) => {
        return true
      })
      .catch(async (error) => {
        debugLog('Error on making recover password: ', error)
        throw error
      })
  }

  /*
   * Request to reset password
   * When the user is obligated to set a new password
   */
  resetPassword = async (data) => {
    debugLog('Making reset password: ', data)

    await apiService
      .post('/user/reset-password', data)
      .then((response) => {
        return true
      })
      .catch(async (error) => {
        debugLog('Error on making reset password: ', error)
        throw error
      })
  }

  // Make logout request
  logout = async (alreadyLogoutOnServer = false) => {
    debugLog('Making logout request')
    this._user = null
    // Clean the storage
    AuthStorageManager.clean()
    AuthClientsStorageManager.clean()
    ProductCategoriesStorageManager.clean()
    // make sure that all data is cleaned
    localStorage.clear()

    if (alreadyLogoutOnServer) return true

    try {
      await apiService.get('/user/logout')
    } catch (error) {
      debugLog('Error on logout request: ', error)
    }
    return true
  }
}

const AuthProvider = new _AuthProvider()
export default AuthProvider
